import axios from "axios";
import React, { useState, useEffect } from "react";
import "./Admin.css";

export default function Admin() {
  const [userList, setUserList] = useState([]);
  const [newEmail, setNewEmail] = useState("");
  function fetchUserList() {
    axios
      .get(process.env.REACT_APP_BACKEND_URL + "/user", {
        withCredentials: true,
      })
      .then((response) => {
        setUserList(response.data);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          window.location.href = process.env.REACT_APP_AUTH_URL;
        } else {
          console.log(error);
        }
      });
  }
  function createUser() {
    axios
      .post(process.env.REACT_APP_BACKEND_URL + "/user?email=" + newEmail)
      .then(() => {
        fetchUserList();
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          window.location.href = process.env.REACT_APP_AUTH_URL;
        } else {
          console.log(error);
        }
      });
  }
  function deleteUser(user_id) {
    axios
      .delete(process.env.REACT_APP_BACKEND_URL + "/user/" + user_id)
      .then(() => {
        fetchUserList();
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          window.location.href = process.env.REACT_APP_AUTH_URL;
        } else {
          console.log(error);
        }
      });
  }
  useEffect(fetchUserList, []);
  return (
    <div className="Admin">
      <h1>Administration</h1>
      <h2>Utilisateurs autorisés</h2>
      <ul>
        {userList.map((user) => (
          <li key={user.id} className="user">
            <p>{user.email}</p>{" "}
            <button onClick={() => deleteUser(user.id)}>Supprimer</button>
          </li>
        ))}
      </ul>
      <h2>Ajouter un utilisateur</h2>
      <label htmlFor="new_user">Email CS de l&apos;utilisateur:</label>
      <input
        type="text"
        name="new_user"
        value={newEmail}
        onChange={(event) => setNewEmail(event.target.value)}
      />
      <button onClick={createUser}>Ajouter</button>
    </div>
  );
}
