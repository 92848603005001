import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Cell,
} from "recharts";
import axios from "axios";
import "./Graph.css";

function Graph(props) {
  return (
    <div>
      <h3>{props.title}</h3>
      <BarChart width={600} height={400} data={props.data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={props.xAxis} />
        <YAxis />
        <Tooltip />
        <Bar dataKey={props.yAxis} unit=" colis">
          {props.data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={["#968eaa", "#a71464"][index % 2]}
            />
          ))}
        </Bar>
      </BarChart>
    </div>
  );
}

export default function GraphsView() {
  const [total, setTotal] = useState(0);
  const [retrieved, setRetrieved] = useState(0);
  const [meanTime, setMeanTime] = useState(0);
  const [bySender, setBySender] = useState([]);
  const [byService, setByService] = useState([]);
  const fetchData = (route, setData) => {
    axios
      .get(process.env.REACT_APP_BACKEND_URL + route, {
        withCredentials: true,
      })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          window.location.href = process.env.REACT_APP_AUTH_URL;
        } else {
          console.log(error);
        }
      });
  };
  useEffect(() => {
    fetchData("/stats/total", setTotal);
    fetchData("/stats/retrieved", setRetrieved);
    fetchData("/stats/mean", setMeanTime);
    fetchData("/stats/sender", setBySender);
    fetchData("/stats/service", setByService);
  }, []);
  return (
    <div>
      <div className="graph">
        <h1>Statistiques du Magasin</h1>
        <a
          className="download-button"
          href={process.env.REACT_APP_BACKEND_URL + "/stats/xlsx"}
          download
        >
          Télécharger les données
        </a>
        <div className="section">
          <div className="stat">
            <h3>Nombre de colis livré</h3>
            <CountUp end={total} duration={2} className="CountUp"></CountUp>
          </div>
          <div className="stat">
            <h3>Nombre de colis retiré</h3>
            <CountUp end={retrieved} duration={2} className="CountUp"></CountUp>
          </div>
          <div className="stat">
            <h3>Temps de retrait moyen</h3>
            <p className="CountUp">{meanTime.toFixed(2)} jours</p>
          </div>
        </div>
        <div className="section">
          <Graph
            title="Colis reçu par service"
            data={byService}
            xAxis="name"
            yAxis="total"
          />
          <Graph
            title="Colis reçu par expéditeur"
            data={bySender}
            xAxis="name"
            yAxis="total"
          />
        </div>
      </div>
    </div>
  );
}
