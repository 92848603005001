import React from "react";
import { Route, Link, Switch, useLocation } from "react-router-dom";
import MainView from "./views/Main";
import GraphsView from "./views/Graphs";
import HelpView from "./views/Help";
import AdminView from "./views/Admin";
import "./App.css";

export default function App() {
  const path = useLocation().pathname;
  return (
    <div className="App">
      <nav>
        <ul>
          <li>
            <Link className={path === "/" ? "current" : ""} to="/">
              Réception
            </Link>
          </li>
          <li>
            <Link className={path === "/graphs" ? "current" : ""} to="/graphs">
              Statistiques
            </Link>
          </li>
          <li>
            <Link className={path === "/admin" ? "current" : ""} to="/admin">
              Administration
            </Link>
          </li>
          <li>
            <Link className={path === "/help" ? "current" : ""} to="/help">
              Aide
            </Link>
          </li>
        </ul>
      </nav>
      <Switch>
        <Route path="/" exact component={MainView} />
        <Route path="/graphs" exact component={GraphsView} />
        <Route path="/help" exact component={HelpView} />
        <Route path="/admin" exact component={AdminView} />
      </Switch>
    </div>
  );
}
