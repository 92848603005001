import React from "react";
import "./Help.css";
import accueil from "../static/671c0d778a1c49eaa1e93c3b666d7271.png";
import navbar from "../static/671c0d778a1c49eaa1e93c3b666d7271.png";
import add from "../static/56ee8890c3044ae7ad6079844a9f8148.png";
import search from "../static/aeca08e5cc2f4297890b4557daa43cc1.png";
import edit from "../static/5b12d0b2a5b54b658be3aff3d690dfbf.png";
import add_popup from "../static/bd60f8e190c7463abe2db72da54a87c9.png";
import list from "../static/4e915dbf941549e58b7ebf48ce582a1f.png";
import selection from "../static/78d2e26b51b04099b574465cd673ad2f.png";
import editMenu from "../static/16d81f3fc435421b83951ddd8bf40401.png";
import retrieve from "../static/456dd9a6feb345b3bcf42f6abe6771af.png";
import searchbar from "../static/47e32862789e4e17b35f366cd5cd00b9.png";

export default function Help() {
  return (
    <div className="Help">
      <h1 id="description-générale">Description générale</h1>
      <p>
        Le logiciel de gestion des livraisons du magasin est disponible à
        l&apos; adresse{" "}
        <a
          data-from-md
          title="https://colis-dpiet.centralesupelec.fr"
          href="https://colis-dpiet.centralesupelec.fr"
        >
          colis-dpiet.centralesupelec.fr
        </a>{" "}
        ce site permet d&apos; ajouter de nouveau colis de modifier leurs
        informations et de le retirer quand quelqu&apos; un vient récupérer un
        colis.
      </p>
      <p>Le site se sépare en 4 grandes sections:</p>
      <ul>
        <li>
          Réception: la partie la plus importante qui permet de gérer les colis
        </li>
        <li>
          Statistiques: permet de visualiser des statistiques sur le magasin
        </li>
        <li>
          Administration: permet d&apos; autoriser des personnes à utiliser le
          logiciel
        </li>
        <li>Aide: permet de consulter ce manuel en ligne</li>
      </ul>
      <h1 id="interface">Interface</h1>
      <p>
        A l&apos; arrivée sur le site vous accédez directement à l&apos; écran
        de réception
      </p>
      <img
        src={accueil}
        alt="accueil.png"
        width="875"
        height="408"
        className="jop-noMdConv"
      />
      <p>
        Pour naviguer entre les différentes sections vous pouvez utiliser la
        barre de navigation en haut de l&apos; écran et clique sur le nom de la
        section qui vous intéresse
      </p>
      <p>
        <img src={navbar} alt="navbar.png" />
      </p>
      <p>Sur la page de réception vous trouverez 4 éléments importants:</p>
      <ol>
        <li>Le boutton ajouter un colis</li>
      </ol>
      <img
        src={add}
        alt="add.png"
        width="139"
        height="34"
        className="jop-noMdConv"
      />
      <ol start="2">
        <li>
          La barre de recherche qui permet de rechercher un colis selon le
          critère selectionné
        </li>
      </ol>
      <img
        src={search}
        alt="search.png"
        width="731"
        height="31"
        className="jop-noMdConv"
      />
      <ol start="3">
        <li>
          <p>
            Le menu d&apos; édition qui permet de modifier les informations du
            colis sélectionné
            <br />
            <img
              src={edit}
              alt="edit.png"
              width="213"
              height="584"
              className="jop-noMdConv"
            />
          </p>
        </li>
        <li>
          <p>
            La liste des colis qui affiche les colis non retiré ou les résultats
            de la recherche le cas échéant
          </p>
        </li>
      </ol>
      <img
        src={list}
        alt="list.png"
        width="684"
        height="356"
        className="jop-noMdConv"
      />
      <h1 id="actions-courantes">Actions courantes</h1>
      <p>Cette partie décrit les actions les plus courantes</p>
      <h2 id="ajouter-un-colis">Ajouter un colis</h2>
      <p>
        Pour ajouter un colis rendez vous dans la section réception (voir la
        section interface pour apprendre à naviguer entre les sections)
      </p>
      <p>
        Cliquer ensuite sur le bouton &quot;<em>Ajouter un colis</em>&quot;
      </p>
      <p>
        <img src={add} alt="add.png" />
      </p>
      <p>Un menu apparait pour saisir les détails du colis:</p>
      <img
        src={add_popup}
        alt="add-popup.png"
        width="675"
        height="253"
        className="jop-noMdConv"
      />
      <p>
        Vous pouvez alors saisir tous les détails du colis. Soyez sans crainte
        toutes ces informations pourront être modifiées ou rajoutées plus tard
        depuis le menu d&apos; édition.
      </p>
      <p>
        Enfin cliquer sur &quot;<em>Enregistrer</em>&quot; pour ajouter votre
        colis.
      </p>
      <blockquote>
        <p>
          Le site va alors utiliser le nom du destinataire pour trouver son
          addresse mail si aucune adresse mail a été précisé.
        </p>
      </blockquote>
      <h2 id="modifier-un-colis">Modifier un colis</h2>
      <p>
        Pour modifier un colis commencez par sélectionner le colis que vous
        voulez modifier dans la liste des colis sur la droite en cliquant
        dessus. La ligne correspondante apparait alors en violet pour montrer
        qu&apos; il a été sélectionné.
      </p>
      <img
        src={selection}
        alt="Sélection d'une ligne"
        width="834"
        height="104"
        className="jop-noMdConv"
      />
      <p>
        Vous pouvez alors modifier les informations du colis dans le menu
        d&apos; édition à gauche
      </p>
      <img
        src={editMenu}
        alt="Menu d'édition"
        width="465"
        height="543"
        className="jop-noMdConv"
      />
      <p>
        Une fois les informations modifiées, cliquez sur le bouton &quot;
        <em>Enregistrer</em>&quot; en bas du menu d&apos; édition pour
        sauvegarder les modifications.
      </p>
      <blockquote>
        <p>
          Si l&apos; envoi du mail à échouer lors de la création du colis le
          site va tenter d&apos; envoyer le mail à nouveau en prenant en compte
          les nouvelles informations
        </p>
      </blockquote>
      <h2 id="retirer-un-colis">Retirer un colis</h2>
      <p>
        Lorsque quelqu&apos; un vient chercher un colis, sélectionnez le colis
        dans la liste puis cliquez sur &quot;<em>Retirer le colis</em>&quot;
        dans le menu d&apos; édition. Une nouvelle section apparaît alors dans
        laquelle vous pouvez préciser le nom de la personne qui retire le colis
        et la date du retrait.
      </p>
      <p>
        <img src={retrieve} alt="Bouton de retrait" />
      </p>
      <p>
        Remplissez les informations demandées puis cliquez sur &quot;
        <em>Enregistrer</em>&quot; afin de sauvegarder le retrait.
      </p>
      <h2 id="rechercher-un-colis">Rechercher un colis</h2>
      <p>
        Pour rechercher un colis cliquez sur le menu à gauche (Partie 1 sur
        l&apos; image) de la barre de recherche pour sélectionner le critère de
        recherche. Puis taper votre recherche dans la partie droite (Partie 2
        sur l&apos; image) de la barre de recherche, les résultats apparraissent
        automatiquement dans la liste des colis.
      </p>
      <p>
        <img src={searchbar} alt="Barre de recherche" />
      </p>
      <h2 id="supprimer-un-colis">Supprimer un colis</h2>
      <p>
        En cas d&apos; erreur vous pouvez supprimer un colis en cliquant sur le
        bouton supprimer en bas du menu d&apos; édition et en confirmant votre
        choix en cliquant sur supprimer dans la fenêtre de confirmation.q
      </p>
    </div>
  );
}
