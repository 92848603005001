import React, { useState, useEffect } from "react";
import axios from "axios";
import "./EditColis.css";

/**
 * Components permettant l'édition d'un colis
 *
 * @export
 * @param {colis, update}
 *  - colis: colis à éditer
 *  - update: fonction permettant de mettre à jour la liste des colis du parent
 * @return {*}
 */
export default function EditColis(props) {
  const [colis, setColis] = useState(props.colis);
  const [deletePopup, setDeletePopup] = useState(false);
  const [validNumber, setValidNumber] = useState(true);

  function verifyNumber(orderNumber) {
    setValidNumber(
      orderNumber.match(
        /^45000[0-9]{5}$|^34(07|25|26|27)LO[0-9]{5}$|^1257LO[0-9]{5}$|BL N°[0-9]+$|^$/
      )
    );
  }

  useEffect(() => {
    setColis(props.colis);
    if (props.colis) {
      verifyNumber(props.colis.order_number);
    }
  }, [props.colis]);

  function handleChange(event) {
    setColis({ ...colis, [event.target.name]: event.target.value });
    if (event.target.name === "order_number") {
      verifyNumber(event.target.value);
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    axios
      .put(process.env.REACT_APP_BACKEND_URL + "/colis", colis)
      .then(props.update)
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          window.location.href = process.env.REACT_APP_AUTH_URL;
        } else {
          console.log(error);
        }
      });
  }

  function handleSendToBreguet() {
    setColis({ ...colis, sent_to_breguet: colis && !colis.sent_to_breguet });
  }

  function handleRetrieval() {
    setColis({
      ...colis,
      retrieved: true,
      retrieval_date:
        new Date().getFullYear() +
        "-" +
        ("0" + (new Date().getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + new Date().getDate()).slice(-2),
    });
  }

  function handleDelete() {
    axios
      .delete(process.env.REACT_APP_BACKEND_URL + "/colis/" + colis.id)
      .then(props.update);
    setDeletePopup(false);
  }

  return (
    <div className="EditColis">
      {deletePopup ? (
        <div className="popup-container">
          <div className="popup">
            <h2>Etes-vous sûr de vouloir supprimer ce colis ?</h2>
            <button className="red" onClick={handleDelete}>
              Supprimer
            </button>
            <button
              onClick={() => {
                setDeletePopup(false);
              }}
            >
              Annuler
            </button>
          </div>
        </div>
      ) : null}
      {colis ? (
        <form onSubmit={handleSubmit} method={"post"}>
          <p className="statut">
            Statut: {colis && colis.retrieved ? "Retiré ✅" : "En attente ⏱"}
          </p>
          {colis.email_sent ? null : (
            <div className="warn">
              L&apos;envoi du mail automatique a échoué, veuillez contacter le
              destinataire manuellement
            </div>
          )}
          <h3>Livraison</h3>
          <div className="separator"></div>
          <div className="form-fields">
            <div className="form-input">
              <label htmlFor="sender">Expéditeur</label>
              <input
                type="text"
                id="sender"
                name="sender"
                value={colis.sender}
                onChange={handleChange}
              />
            </div>
            <div className="form-input">
              <label htmlFor="delivery_date">Date de Livraison</label>
              <input
                type="date"
                id="delivery_date"
                name="delivery_date"
                value={colis.delivery_date}
                onChange={handleChange}
              />
            </div>
            <div className="form-input">
              <label htmlFor="order_number">N° Bon de commande</label>
              <input
                type="text"
                id="order_number"
                name="order_number"
                value={colis.order_number}
                onChange={handleChange}
              />
              {!validNumber && (
                <span className="warning-text">
                  <svg
                    width="15px"
                    height="15px"
                    viewBox="0 0 24 24"
                    fill="#be3636"
                    xmlns="https://www.w3.org/2000/svg"
                  >
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path>
                  </svg>
                  &nbsp; N° SIFAC invalide
                </span>
              )}
            </div>
            <div className="form-input">
              <label htmlFor="shipping_number">N° Bon de livraison</label>
              <input
                type="text"
                id="shipping_number"
                name="shipping_number"
                value={colis.shipping_number}
                onChange={handleChange}
              />
            </div>
            <div className="form-input">
              <label htmlFor="number_of_colis">Nombre de colis</label>
              <input
                type="number"
                id="number_of_colis"
                name="number_of_colis"
                value={colis.number_of_colis}
                onChange={handleChange}
              />
            </div>
            <div className="form-input">
              <label htmlFor="colis_type">Type de colis</label>
              <select
                id="colis_type"
                name="colis_type"
                value={colis.colis_type}
                onChange={handleChange}
              >
                <option value="colis">Colis</option>
                <option value="courrier">Courrier</option>
                <option value="palette">Palette</option>
                <option value="réfrigéré">Réfrigéré</option>
              </select>
            </div>
            <div className="form-input">
              <label htmlFor="sent_to_breguet">Transféré à Bréguet</label>
              <input
                type="checkbox"
                id="sent_to_breguet"
                name="sent_to_breguet"
                checked={colis.sent_to_breguet}
                onChange={handleSendToBreguet}
              />
            </div>
            <div className="form-input">
              <label htmlFor="comment">Observations</label>
              <textarea
                id="comment"
                name="comment"
                value={colis.comment}
                onChange={handleChange}
              />
            </div>
          </div>
          <h3>Destinataire</h3>
          <div className="separator"></div>
          <div className="form-fields">
            <div className="form-input">
              <label htmlFor="addressee">Preneur prioritaire</label>
              <div style={{ display: "grid" }}>
                <input
                  type="text"
                  id="addressee"
                  name="addressee_first_name"
                  value={colis.addressee_first_name}
                  onChange={handleChange}
                  placeholder="Prénom"
                />
                <input
                  type="text"
                  id="addressee_last_name"
                  name="addressee_last_name"
                  value={colis.addressee_last_name}
                  onChange={handleChange}
                  placeholder="Nom"
                />
              </div>
            </div>

            <div className="form-input">
              <label htmlFor="service">Service</label>
              <input
                type="text"
                id="service"
                name="service"
                value={colis.service}
                onChange={handleChange}
              />
            </div>
            <div className="form-input">
              <label htmlFor="email">Email</label>
              <input
                type="text"
                id="email"
                name="email"
                placeholder="Optionel"
                value={colis.email}
                onChange={handleChange}
              />
            </div>
          </div>

          {colis.retrieved ? (
            <div>
              <h3>Retrait</h3>
              <div className="separator"></div>
              <div className="form-fields">
                <div className="form-input">
                  <label htmlFor="retriever">Récupéré par</label>
                  <div style={{ display: "grid" }}>
                    <input
                      type="text"
                      id="retriever"
                      name="retriever_first_name"
                      value={colis.retriever_first_name}
                      onChange={handleChange}
                      placeholder="Prénom"
                    />
                    <input
                      type="text"
                      id="retriever"
                      name="retriever_last_name"
                      value={colis.retriever_last_name}
                      onChange={handleChange}
                      placeholder="Nom"
                    />
                  </div>
                </div>

                <div className="form-input">
                  <label htmlFor="retrieval_date">Date de Retrait</label>
                  <input
                    type="date"
                    id="retrieval_date"
                    name="retrieval_date"
                    value={colis.retrieval_date}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          ) : null}

          <div className="button-group">
            {!colis.retrieved ? (
              <button className="secondary" onClick={handleRetrieval}>
                Retirer le colis
              </button>
            ) : null}
            <button className="primary" type="submit" value="Enregistrer">
              Enregistrer
            </button>
            <button
              className="red"
              onClick={() => {
                setDeletePopup(true);
              }}
            >
              Supprimer
            </button>
          </div>
        </form>
      ) : (
        <h2>Aucun colis sélectionné</h2>
      )}
    </div>
  );
}
