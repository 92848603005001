import React, { useState, useEffect } from "react";
import axios from "axios";

import "./Main.css";
import ColisTable from "../components/ColisTable";
import EditColis from "../components/EditColis";
import CreateColis from "../components/CreateColis";
import SearchBar from "../components/SearchBar";

export default function Main() {
  const [colisList, setColisList] = useState([]);
  const [selected, setSelected] = useState();
  const [displayCreate, setDisplayCreate] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchAttribute, setSearchAttribute] = useState("colis_id");
  const [onlyBreguet, setOnlyBreguet] = useState(false);
  function fetchColisList() {
    if (searchValue !== "") {
      axios
        .get(
          process.env.REACT_APP_BACKEND_URL +
            "/colis/search?attribute=" +
            searchAttribute +
            "&search=" +
            searchValue,
          {
            withCredentials: true,
          }
        )
        .then((response) => {
          if (onlyBreguet) {
            setColisList(response.data.filter(colis => colis.sent_to_breguet));
          }
          else {
            setColisList(response.data)
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 403) {
            window.location.href = process.env.REACT_APP_AUTH_URL;
          } else {
            console.log(error);
          }
        });
    } else {
      axios
        .get(process.env.REACT_APP_BACKEND_URL + "/colis/unretrieved", {
          withCredentials: true,
        })
        .then((response) => {
          if (onlyBreguet) {
            setColisList(response.data.filter(colis => colis.sent_to_breguet) || []);
          }
          else {
            setColisList(response.data)
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 403) {
            window.location.href = process.env.REACT_APP_AUTH_URL;
          } else {
            console.log(error);
          }
        });
    }
  }
  useEffect(fetchColisList, [searchValue, searchAttribute, onlyBreguet]);
  return (
    <div className="Main">
      {displayCreate ? (
        <CreateColis
          handleClose={() => setDisplayCreate(false)}
          update={fetchColisList}
        />
      ) : (
        ""
      )}
      <div style={{ display: "flex" }}>
        <button onClick={() => setDisplayCreate(true)}>Ajouter un colis</button>
        <SearchBar
          attribute={searchAttribute}
          setAttribute={setSearchAttribute}
          search={searchValue}
          setSearch={setSearchValue}
        />
        <label htmlFor="breguet">Afficher uniquement les colis envoyé à Bréguet </label>
        <input className="filter-checkbox" type="checkbox" name="breguet" checked={onlyBreguet} onChange={(event) => {setOnlyBreguet(event.target.checked)}}/>
      </div>
      {colisList[0] && (
        <div className="colis-edition">
          <EditColis
            colis={colisList.filter((colis) => colis.id === selected)[0]}
            update={fetchColisList}
          />
          <div className="table-overflow">
            <ColisTable
              colisList={colisList}
              selected={selected}
              select={setSelected}
            />
          </div>
        </div>
      )}
    </div>
  );
}
