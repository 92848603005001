import React, { useState } from "react";
import axios from "axios";
import "./CreateColis.css";

export default function CreateColis(props) {
  const [colis, setColis] = useState({
    sender: "",
    delivery_date:
      new Date().getFullYear() +
      "-" +
      ("0" + (new Date().getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + new Date().getDate()).slice(-2), // Calcule la date actuelle au format ISO 8601
    number_of_colis: 1,
    addressee_first_name: "",
    addressee_last_name: "",
    service: "",
    order_number: "",
    shipping_number: "",
    colis_type: "colis",
    sent_to_breguet: false,
    email: "",
    comment: "",
  });
  const [validNumber, setValidNumber] = useState(true);
  function handleChange(event) {
    setColis({ ...colis, [event.target.name]: event.target.value });
    setValidNumber(
      colis.order_number.match(
        /^45000[0-9]{5}$|^34(07|25|26|27)LO[0-9]{5}$|^1257LO[0-9]{5}$|BL N°[0-9]+$|^$/
      )
    );
  }
  function handleSubmit() {
    axios
      .post(process.env.REACT_APP_BACKEND_URL + "/colis", colis)
      .then(props.update)
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          window.location.href = process.env.REACT_APP_AUTH_URL;
        } else {
          console.log(error);
        }
      });
    props.handleClose();
  }
  return (
    <div className="popup-container">
      <div className="popup">
        <h2>Créer un colis</h2>
        <form>
          <div className="form-fields">
            <div className="form-input">
              <label htmlFor="first_name">Destinataire</label>
              <input
                type="text"
                id="addressee_first_name"
                name="addressee_first_name"
                placeholder="Prénom"
                value={colis.addressee_first_name}
                onChange={handleChange}
              />
              <input
                type="text"
                id="addressee_last_name"
                name="addressee_last_name"
                placeholder="Nom"
                value={colis.addressee_last_name}
                onChange={handleChange}
              />
            </div>
            <div className="form-input">
              <label htmlFor="sender">Email du destinataire</label>
              <input
                type="text"
                id="email"
                name="email"
                onChange={handleChange}
                value={colis.email}
                placeholder="Optionnel"
              />
            </div>
            <div className="form-input">
              <label htmlFor="sender">Expéditeur</label>
              <input
                type="text"
                id="sender"
                name="sender"
                onChange={handleChange}
                value={colis.sender}
              />
            </div>
            <div className="form-input">
              <label htmlFor="delivery_date">Date de Livraison</label>
              <input
                type="date"
                id="delivery_date"
                name="delivery_date"
                value={colis.delivery_date}
                onChange={handleChange}
              />
            </div>
            <div className="form-input">
              <label htmlFor="number_of_colis">Nombre de colis</label>
              <input
                type="number"
                id="number_of_colis"
                name="number_of_colis"
                value={colis.number_of_colis}
                onChange={handleChange}
              />
            </div>
            <div className="form-input">
              <label htmlFor="service">Service</label>
              <input
                type="text"
                id="service"
                name="service"
                value={colis.service}
                onChange={handleChange}
              />
            </div>
            <div className="form-input">
              <label htmlFor="order_number">N° Bon de commande</label>
              <input
                type="text"
                id="order_number"
                name="order_number"
                value={colis.order_number}
                onChange={handleChange}
              />
              {!validNumber && (
                <span className="warning-text">
                  <svg
                    width="15px"
                    height="15px"
                    viewBox="0 0 24 24"
                    fill="#be3636"
                    xmlns="https://www.w3.org/2000/svg"
                  >
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path>
                  </svg>
                  &nbsp; N° SIFAC invalide
                </span>
              )}
            </div>
            <div className="form-input">
              <label htmlFor="shipping_number">N° Bon de livraison</label>
              <input
                type="text"
                id="shipping_number"
                name="shipping_number"
                value={colis.shipping_number}
                onChange={handleChange}
              />
            </div>
            <div className="form-input">
              <label htmlFor="colis_type">Type de colis</label>
              <select
                id="colis_type"
                name="colis_type"
                value={colis.colis_type}
                onChange={handleChange}
              >
                <option value="colis">Colis</option>
                <option value="courrier">Courrier</option>
                <option value="palette">Palette</option>
                <option value="réfrigéré">Réfrigéré</option>
              </select>
            </div>
            <div className="form-input">
              <label htmlFor="comment">Observations</label>
              <textarea
                type="text-area"
                id="comment"
                name="comment"
                value={colis.comment}
                onChange={handleChange}
              />
            </div>
          </div>
        </form>
        <button onClick={handleSubmit}>Enregistrer</button>
        <button onClick={props.handleClose}>Annuler</button>
      </div>
    </div>
  );
}
