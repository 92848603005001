import React, { useState, useEffect } from "react";
import "./ColisTable.css";

export default function ColisTable(props) {
  const [colisList, setColisList] = useState(props.colisList);
  useEffect(() => {
    setColisList(props.colisList);
  }, [props.colisList]);
  return (
    <table className="ColisTable">
      <thead>
        <tr>
          <th>N° de Colis</th>
          <th>Date de réception</th>
          <th>Retiré</th>
          <th>Destinataire</th>
          <th>Service</th>
          <th>N° de commande</th>
          <th>Expéditeur</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {colisList.map((colis) => (
          <tr
            key={colis.id}
            className={
              "Colis" +
              (colis.id === props.selected ? " selected" : "") + // Display in yellow if older than 1 mont and in red if older than 1 year
              (new Date().getFullYear() >
              new Date(colis.delivery_date).getFullYear()
                ? " red"
                : new Date().getMonth() >
                  new Date(colis.delivery_date).getMonth()
                ? " yellow"
                : "")
            }
            onClick={() => props.select(colis.id)}
          >
            <td>{colis.colis_id}</td>
            <td>{colis.delivery_date}</td>
            <td>{colis.retrieved ? "✅" : "⏱"}</td>
            <td>
              {colis.addressee_first_name} {colis.addressee_last_name}
            </td>
            <td>{colis.service}</td>
            <td>{colis.order_number}</td>
            <td>{colis.sender}</td>
            <td
              title={
                colis.email_sent ? "" : "L'envoi du mail automatique a échoué"
              }
            >
              {colis.email_sent ? "" : "🚨"}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
