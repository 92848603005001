import React, { useEffect, useState } from "react";
import "./SearchBar.css";

function SelectDropDown(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [showList, setShowList] = useState(false);
  const [animation, setAnimation] = useState("");
  function toggle() {
    setIsOpen(!isOpen);
  }
  function handleSelect(event) {
    console.log(event.target.key);
    props.setSelected(event.target.id);
    setIsOpen(false);
  }
  useEffect(() => {
    if (isOpen) {
      setShowList(true);
      const timer = setTimeout(() => {
        setAnimation(" open");
      }, 10);
      return () => {
        clearTimeout(timer);
      };
    } else {
      setAnimation("");
      const timer = setTimeout(() => {
        setShowList(false);
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [isOpen]);
  return (
    <div className="select-dropdown">
      <div className="select-dropdown-header" onClick={toggle}>
        {
          props.options.filter((option) => {
            return option.value === props.value;
          })[0].title
        }
      </div>
      {showList && (
        <div className={"select-dropdown-list" + animation}>
          <ul>
            {props.options.map((option) => (
              <li id={option.value} key={option.value} onClick={handleSelect}>
                {option.title}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default function SearchBar(props) {
  return (
    <div className="searchbar">
      <SelectDropDown
        value={props.attribute}
        setSelected={props.setAttribute}
        options={[
          { title: "N° de Colis", value: "colis_id" },
          { title: "Date de réception", value: "delivery_date" },
          { title: "Destinataire", value: "addressee" },
          { title: "Service", value: "service" },
          { title: "Expéditeur", value: "sender" },
          { title: "N° de Commande", value: "order_number" },
          { title: "N° de Livraison", value: "shipping_number" },
        ]}
      />
      <input
        type="text"
        value={props.search}
        onChange={(event) => props.setSearch(event.target.value)}
      ></input>
    </div>
  );
}
